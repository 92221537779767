<template>
  <div id="login">
    <div class="wrapper">
      <header>
        <img
          v-if="!reset"
          src="@/assets/img/top-login/logo-alltop.png"
          alt="えひめ情報リテラシー向上事業"
        />
      </header>

      <main>
        <form ref="form" @submit.prevent>
          <fieldset>
            <legend v-if="reset">パスワード再設定</legend>
            <legend v-else>サインイン</legend>
            <ValidationText
              id="username"
              ref="user"
              key="username"
              v-model="user_id"
              type="text"
              value=""
              placeholder="ユーザー名"
              left-icon="user"
              :checkpoints="userCheckpoints"
              :disabled="reset ? true : null"
              @validate="validate"
            />

            <ValidationText
              v-if="reset"
              id="passcode"
              ref="passcode"
              key="passcode"
              v-model="passcode"
              type="number"
              placeholder="パスコード"
              left-icon="pass"
              :checkpoints="passcodeCheckpoints"
              @validate="validate"
            />

            <ValidationText
              id="password"
              ref="pass"
              key="password"
              v-model="password"
              type="password"
              placeholder="パスワード"
              left-icon="pass"
              right-icon="eye"
              right-icon-type="toggle"
              :dict="[user_id]"
              :checkpoints="reset ? passCheckpoints : loginPassCheckpoints"
              @validate="validate"
            />

            <ValidationText
              v-if="reset"
              id="retype"
              ref="retype"
              key="retype"
              v-model="retype"
              type="password"
              placeholder="パスワード再確認"
              left-icon="pass"
              right-icon="eye"
              right-icon-type="toggle"
              :dict="[password]"
              :checkpoints="retypeCheckpoints"
              @validate="validate"
            />

            <div v-if="reset" class="button_wrapper">
              <button :disabled="valid ? null : true" @click="reset_onClick">
                再設定
              </button>
              <button @click="reset_cansell_onClick">キャンセル</button>
            </div>
            <div v-else class="button_wrapper">
              <button :disabled="valid ? null : true" @click="login_onClick">
                ログイン
              </button>
            </div>
          </fieldset>
        </form>
      </main>
    </div>
  </div>
</template>

<script>
import { ERROR } from '@/service/error';
import { loginCheckpoints } from '@/service/checkpoints';
import { NO_PASSWORD_VALIDATION } from '../defines';
import Dialog from '@/components/Dialog.vue';
import dialogs from '@/mixins/dialogs';

export default {
  components: {
    ValidationText: () => import('@/components/ValidationText.vue')
  },
  mixins: [dialogs],
  props: {
    next: { type: String }
  },
  data: () => ({
    reset: false,
    passcode: '',
    retype: '',
    valid: null,
    user_id: '',
    password: '',
    userCheckpoints: loginCheckpoints.userCheckpoints,
    loginPassCheckpoints: loginCheckpoints.loginPassCheckpoints,
    passCheckpoints: loginCheckpoints.passCheckpoints,
    passcodeCheckpoints: loginCheckpoints.passcodeCheckpoints,
    retypeCheckpoints: loginCheckpoints.retypeCheckpoints,
    errors: {
      userId: '',
      password: ''
    }
  }),
  methods: {
    validate() {
      let hasError = this.$refs.user.hasError || this.$refs.pass.hasError;
      if (this.reset) {
        hasError =
          hasError ||
          this.$refs.passcode.hasError ||
          this.$refs.retype.hasError;
      }
      if (NO_PASSWORD_VALIDATION) this.valid = true;
      this.valid = !hasError;
    },
    async reset_onClick() {
      if (this.$loading()) return;
      let result;
      try {
        const payload = {
          login_id: this.user_id,
          password: this.password,
          passcode: this.passcode
        };
        result = await this.$store.dispatch('api/setPassword', payload);
      } finally {
        this.$unloading();
      }
      if (result.success) {
        this.login_onClick();
      } else {
        switch (result.error.code) {
          default: {
            await this.$confirm({
              component: Dialog,
              props: {
                title: 'パスワード[更新:こうしん]に[失敗:しっぱい]しました',
                message: result.error.message
              }
            });
          }
        }
        return;
      }
    },
    async reset_cansell_onClick() {
      this.reset = false;
    },
    async login_onClick() {
      if (this.$loading()) return;
      let result;
      try {
        var data = {
          id: this.user_id,
          password: this.password
        };
        console.log('login', data);
        result = await this.$store.dispatch('auth/auth', data);
        console.log('login result', result);
      } finally {
        this.$unloading();
      }
      if (result.success) {
        if (this.next) {
          this.$router.push(this.next);
        } else {
          this.$router.push('/');
        }
      } else {
        switch (result.error.code) {
          case ERROR.PasswordHasReset.code:
            this.reset = true;
            break;
          default: {
            await this.$confirm({
              component: Dialog,
              props: {
                title: 'ログインに[失敗:しっぱい]しました',
                message: result.error.message
              }
            });
          }
        }
        return;
      }
    }
  }
};
</script>

<style scoped>
#login {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3vh;
  background-image: linear-gradient(
    -45deg,
    #fdf5cf 25%,
    #fff0b3 25%,
    #fff0b3 50%,
    #fdf5cf 50%,
    #fdf5cf 75%,
    #fff0b3 75%,
    #fff0b3
  );
  background-size: 18px 18px;
  background-repeat: repeat;
  z-index: 1;
}
#login::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-image: url(../assets/img/top-login/login-bg.png);
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
}

#login .wrapper {
  max-width: 770px;
  padding: 1em;
  z-index: 3;
}

#login .wrapper,
#login .wrapper > *,
#login main,
#login form,
#login fieldset {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#login fieldset {
  align-items: stretch;
}
#login img {
  width: 100%;
  height: auto;
  max-height: 50vh;
  object-fit: scale-down;
}

#login legend {
  display: block;
  text-align: center;
}
#username,
#password,
#passcode,
#retype {
  margin-top: 0.5em;
  min-width: 18em;
}
#username {
  z-index: 15;
}
#passcode {
  z-index: 14;
}
#password {
  z-index: 13;
}
#retype {
  z-index: 12;
}
#login fieldset {
  margin-top: 1em;
}
#login .button_wrapper {
  margin: 0.5em 0 0 0;
  display: flex;
  flex-direction: column;
}
#login button {
  border: #1bb222;
  background-color: #1bb222;
  color: #fff;
  cursor: pointer;

  position: relative;
  display: block;
  margin-top: 0.5em;
  padding: 0.25em 1em;
  border: none;
  outline: none;
  border-radius: 2em;
  font-size: inherit;
  z-index: 1;
  transition-duration: 0.1s;
  box-sizing: content-box;
}
#login button:hover {
  border: #60e860;
  background-color: #60e860;
}
#login button:active {
  border: #60e860;
  background-color: #60e860;
  transform: scale(0.9);
}
#login button:disabled {
  color: #a8a8a8;
  border: #b5b5b5;
  background-color: #e2e2e2;
  border-width: 1px;
}

@media screen and (min-aspect-ratio: 3/2) {
  #login::before {
    background-size: 100% auto;
  }
}
@media screen and (max-aspect-ratio: 2/3) {
  #login {
    align-items: stretch;
    font-size: 3vw;
  }
  #login::before {
    background-image: none;
  }
  #login img[data-v-26084dc2] {
    width: 90%;
    margin: 5% 0 10% 0;
  }
  #login .wrapper {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(../assets/img/top-login/login-bg.png);
  }
  #login main,
  #login form,
  #login fieldset {
    width: 100%;
  }
}
@media screen and (max-aspect-ratio: 9/16) {
  #login {
    font-size: 4vw;
  }
}
</style>
