export const ERROR = {
  AuthFailed: {
    name: 'AuthFailed',
    code: 4003,
    message: '[認証:にんしょう]に失敗しました。'
  },
  NoAccessToken: {
    name: 'NoAccessToken',
    code: 4001,
    message: 'アクセストークンがありません。'
  },
  InvalidAccessToken: {
    name: 'InvalidAccessToken',
    code: 4002,
    message: 'アクセストークンが[不正:ふせい]です。'
  },
  InsertFailed: { name: 'InsertFailed', code: 4004, message: 'Insert Failed' },
  UpdateFailed: { name: 'UpdateFailed', code: 4005, message: 'Update Failed' },
  SelectFailed: { name: 'SelectFailed', code: 4006, message: 'Select Failed' },
  DeleteFailed: { name: 'DeleteFailed', code: 4007, message: 'Delete Failed' },
  FileNotFound: {
    name: 'FileNotFound',
    code: 4008,
    message: 'ファイルが[存在:そんざい]しません。'
  },
  UserNotFound: {
    name: 'UserNotFound',
    code: 4009,
    message: 'ユーザーが[存在:そんざい]しません。'
  },
  PasscodeNotMatch: {
    name: 'PasscodeNotMatch',
    code: 4011,
    message: 'パスコードが[一致:いっち]しません。'
  },
  UserNotAdmin: {
    name: 'UserNotAdmin',
    code: 4010,
    message: '[管理者権限:かんりしゃけんげん]がありません。'
  },
  PasswordNotReset: {
    name: 'PasswordNotReset',
    code: 4012,
    message: 'パスワードがリセットされていません。'
  },
  StatusDisabled: {
    name: 'StatusDisabled',
    code: 4013,
    message: 'このユーザーは[有効:ゆうこう]ではありません。'
  },
  PasswordHasReset: {
    name: 'PasswordHasReset',
    code: 4015,
    message: 'パスワードがリセットされています。'
  },
  PasswordDoesNotMatch: {
    name: 'PasswordDoesNotMatch',
    code: 4014,
    message: 'パスワードが[一致:いっち]しません。'
  }
};
