import * as utils from '@/service/utils';

export const controlCheckpoints = {
  '': [],
  numeric: [
    {
      title: '文字の種類',
      check: utils.isNumeric,
      ok: '半角数字が使えます。',
      bad: '半角数字が使えます。'
    }
  ],
  alphabetic: [
    {
      title: '文字の種類',
      check: utils.isAlphanumeric,
      ok: '半角英字が使えます。',
      bad: '半角英字が使えます。'
    }
  ],
  kana: [
    {
      title: '文字の種類',
      check: utils.isKana,
      ok: 'カタカナが使えます。',
      bad: 'カタカナが使えます。'
    }
  ],
  hiragana: [
    {
      title: '文字の種類',
      check: utils.isHiragana,
      ok: 'ヒラガナが使えます。',
      bad: 'ヒラガナが使えます。'
    }
  ]
};

export const loginCheckpoints = {
  userCheckpoints: [
    {
      title: '文字の種類',
      check(v) {
        return /^[0-9a-zA-Z]+$/.test(v);
      },
      ok: 'OK!',
      bad: '半角英数字が使えます。'
    },
    {
      title: '入力確認',
      check(v) {
        return v.length > 0;
      },
      ok: 'OK!',
      bad: 'ユーザ名が入力されていません。'
    }
  ],
  loginPassCheckpoints: [
    {
      title: '文字の種類',
      check(v) {
        return /^[0-9a-zA-Z]*$/.test(v);
      },
      ok: 'OK!',
      bad: '半角英数字が使えます。'
    }
    /*
    {
      title: '長さ',
      check(v) {
        return v.length >= 5;
      },
      ok: 'OK!',
      bad: '5文字以上にしましょう。'
    }
    */
  ],
  passCheckpoints: [
    {
      title: '文字の種類',
      check(v) {
        return /^[0-9a-zA-Z]+$/.test(v);
      },
      ok: 'OK!',
      bad: '半角英数字が使えます。'
    },
    {
      title: '長さ',
      check(v) {
        return v.length >= 5;
      },
      ok: 'OK!',
      bad: '5文字以上にしましょう。'
    }
    /*
    {
      title: '組み合わせ',
      check(v) {
        return /[0-9]/.test(v) && /[a-zA-Z]/.test(v) && /[!#$%&@.*\-_]/.test(v);
      },
      ok: 'OK!',
      bad: '数字と英数字、記号を組み合わせてパスワードを作りましょう。'
    },
    {
      title: '辞書',
      check(v, vt) {
        for (let word of vt.dict) {
          if (v.indexOf(word) >= 0) {
            return false;
          }
        }
        return true;
      },
      ok: 'OK!',
      bad: 'パスワードに類推しやすい言葉が含まれないようにしましょう。'
    }
    */
  ],

  passcodeCheckpoints: [
    {
      title: '',
      check(v) {
        return /^[0-9]+$/.test(v) && v.length === 4;
      },
      ok: 'OK!',
      bad: '4桁の数字を入力してください。'
    }
  ],
  retypeCheckpoints: [
    {
      title: '',
      check(v, vt) {
        return vt.dict[0] === v;
      },
      ok: 'OK!',
      bad: '同じパスワードをもう一度入力してください。'
    }
  ]
};
